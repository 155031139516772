export const structure = [
  {
    type: "text",
    html: `
    <p>The beach occupies what can seem an unimpeachable place in the Australian imaginary. In <em>A Secret Country</em> (1989), Australian political journalist John Pilger argued that the beach was a place given inimitable qualities by the ‘smell and taste’ of salt and sand and (occasional) raw sewerage, the ‘diamond light’ of December sun, and the multicultural and diverse characters that congregated there, and who, in descending upon the sand, were freed of the strictures and discrimination of urban life<sup>1</sup>. On the beach, Pilger claimed, there were ‘no proprietal rights’; the beach was owned by the state, which is to say the people. Pilger used these heterotopic qualities of the beach to suggest that the beach was Australia’s ‘true democracy’ and that a ‘spirit of equality [began] at the promenade steps’<sup>2</sup>. </p>
    <p>These claims were made in contradistinction to Pilger’s own piercing critique of the society that began on the landside of those steps. And in the many ways in which Pilger described the idyll of the Australian beach, he was implying the inverse for that society: one that was corrupt, racist, monocultural, stuck-up, sexually repressed, insular, and addled by its obsession with private property. This conceit was visually represented on the front cover of the book; on the top of the cover, Max Dupain’s Sunbather, an ‘Australian icon’, and a figure with which Pilger deeply personally identified, and, on the bottom, an undated and non-located photograph of an unnamed Aboriginal Australian boy behind bars. Yet, for many Australians, including those who do not look like the Sunbather, Pilger’s identification of the radicality and freedom of the Australian beach will be baffling, or <em>eu</em>-topian, in the sense that, for them, it might never have existed, and could never exist.</p>

    `,
    // value, html, image
    sidenotes: [
      // { image: "/assets/aside-2.jpg" },
      // { image: "/assets/aside-3.jpg" },
      {
        value: 1,
        html: `Pilger, John, The Secret Country (1989), London: Vintage, 1992, pp.9-14`,
      },
      { html :`<br/><br/>`},
      { image: "/assets/CountryCover.jpg" },
      {
        html: `Front cover of A Secret Country by John Pilger.`,
      },
      // {
      //   value: 2,
      //   html: `Pilger`,
      // },
      // {
      //   value: 3,
      //   html: `<em>The Guardian</em> Australia, ‘The Killing Times’. Last updayed 16 March 2022. Available online: <a href="https://www.theguardian.com/australia-news/ng-interactive/2019/mar/04/massacre-map-australia-the-killing-times-frontier-wars" target="_blank" rel="noopener noreferrer">https://www.theguardian.com/australia-news/ng-interactive/2019/mar/04/massacre-map-australia-the-killing-times-frontier-wars</a>`,
      // },
    ],
  },
  {
    type: "text",
    html: `
      <p>After all, the Australian beach was where the invasion began.  Cook’s crew first stepped ashore at Kurnell beach on the north side of Botany Bay. The beach at Manly Cove was where Governor Philip received a spear through the shoulder searching for Bennelong. Throughout the nineteenth century, the topography of the beach was itself pressed into the service of murder, when at the beaches of Fraser Island and Indian Head, and countless others, people were driven into the surf and made to drown at the point of British guns<sup>2</sup>. The beach was thus the site of the beginning of the theft of not only the continent but its people; the first iteration of a frontier of violence that was eventually pushed deep into the continent.</p>
      <p>Conversely, as the colony developed, and out of that colony the nation of Australia emerged, sanitised, glorified visions of the colonised beach came to undergird Australia’s mythos, moving effortlessly, as Affrica Taylor observes, from redcoats hoisting the Union Flag to the red costume of the lifesaver, from the salt-encrusted back of the <em>Sunbather</em> to the high camp of the thong float at the Sydney Olympics closing ceremony<sup>3</sup>. Taylor writes that it is in this sense the beach is a ‘cultural construct’, a means of shoring up certain, privileged Australian identities, a ‘White native’ male identity in particular and which in its practice is categorically exclusionary. In stark contrast to Pilger’s heady vision of the beach as ownerless, this identity <em>demands</em> the ‘proprietal enclosure’ of the beach. To the extent the beach is for the Australian, it must be secured against the Other — be they Indigenous, Muslim, non-heteronormative, or in any other way divergent.</p>
    `,
    // value, html, image
    sidenotes: [
      // { image: "/assets/aside-1.jpg" },
      // { image: "/assets/aside-2.jpg" },
      // { image: "/assets/aside-3.jpg" },
      {
        value: 2,
        html: `<em>The Guardian</em> Australia, ‘The Killing Times’. Last updayed 16 March 2022. Available online: <a href="https://www.theguardian.com/australia-news/ng-interactive/2019/mar/04/massacre-map-australia-the-killing-times-frontier-wars" target="_blank" rel="noopener noreferrer">https://www.theguardian.com/australia-news/ng-interactive/2019/mar/04/massacre-map-australia-the-killing-times-frontier-wars</a>`,
      },
      {
        value: 3,
        html: `Taylor, Africa, ‘Australian Bodies, Australian Sands’ in <em>Noble, Gregory, Lines in the sand: Multiculturalism and national belonging,</em> The Institute of Criminology Series 28, Institute of Criminology Press, 2009.`,
      },
    ],
  },
  {
    type: "media",
    file: "/assets/Landing2.jpg",
    caption: `The figure of the male, white lifesaver, iconic enough by the 1930s to be the bearer of the flag on the occasion of the opening of the Sydney Harbour Bridge. Image: ‘Sydney bridge celebrations poster’ (1932), Douglas Annand and Arthur Whitmore/Australian National Maritime Museum.`,
  },
  {
    type: "text",
    arrangement: "right",
    html: `
<p>In Australia, this enclosure is always operating to dispossess and degrade the people who have since time immemorial been its custodians. At the Australian beach, this violence was targeted at Aboriginal women in particular, who, as the colony developed, were denied the aquatic practices, such as swimming, playing, and collecting food, they had always practiced<sup>4</sup>. When, decades after the first wave of the invasion, Aboriginal men and women were admitted to the beach, it was in the form of segregated swimming races, an arrangement that underscored that the coloniser now dominated the territory<sup>5</sup>.</p>
<p>The proprietal enclosure of the beach by the coloniser has in the twentieth and twenty-first centuries expanded to target many other Othered communities. In 2005, racist slogans were carved into the sand of Cronulla beach following riots and conflict between Lebanese Australians and Anglo-Australians, egged on by the media. During these events, the beach was both territory and media, a sandbox in which to cosplay the re-conquest of White Australia <em>and</em> a text to be scrawled upon, to broadcast nativist screeds. More recently, ocean pools and baths have been a site of excluding transpeople as part of a contrived culture war<sup>6</sup>. This sorry and hateful scapegoating is set against a wider history of queer spaces developing in peripheries upon the periphery, such as at baths and pools beyond or on the edge of the beach, in response to the homophobic violence practiced there.</p>
<p>The beach in Australia has thus consistently functioned as a zone of conflict, not in spite of this history but <em>as a function of it</em> and the mythology into which it has been folded. This condition of the beach is critical to foreground any examination of spatial responses to climate change, to which the response of those in control of Australia will be to remake, re-instrumentalise the beach again. No ground in Australia is neutral ground, least of those territories upon which hinges the country’s most dominant, fragile, and paranoid identity.</p>
    `,
    sidenotes: [
      {
        value: 4,
        html: `Stronach, Megan, Adair, Daryl, and Maxwel Hazel, ‘‘Djabooly-djabooly: why don’t they swim?’: the ebb and flow of water in the lives of Australian Aboriginal women’ in Annals Of Leisure Research 2019, Vol. 22, No. 3, Routlegde, pp.286–304.`,
      },
      {
        value: 5,
        html: `Stronach, Megan, Michelle O'Shea, and Maxwel Hazel, ‘The open Australian beach is a myth: not everyone can access these spaces equally’, The Conversation, January 21, 2021. Available online: <a href="https://theconversation.com/the-open-australian-beach-is-a-myth-not-everyone-can-access-these-spaces-equally-153378" rel="noopener noreferrer" target="_blank">https://theconversation.com/the-open-australian-beach-is-a-myth-not-everyone-can-access-these-spaces-equally-153378</a>`,
      },
      {
        value: 6,
        html: `Gallagher, Alex, ‘The McIver’s Ladies Baths Situation Proves (Again) How Fraught Beaches Are For Trans Bodies’, Pedestrian, January 14, 2021. Available online: <a target="_blank" rel="noopener noreferrer" href="https://www.pedestrian.tv/news/mcivers-womens-baths-trans-bodies/">https://www.pedestrian.tv/news/mcivers-womens-baths-trans-bodies/</a>`,
      },
    ],
  },
  {
    type: "media",
    file: "/assets/Landing4a.jpg",
    caption: "Building works associated with the sea wall at Collaroy. Photo: Oskar Johanson."
  },
  {
    type: "text",
    html: `
<p>This sense of manufacture, or confection, is reflected in the architecture of Australia’s beaches. Australia’s built environment clings to the rim of the continent, its land value magnified by proximity to the coast. The beach is therefore at the core of urban, often privileged Australian imaginaries (although many beaches remain wild places, beyond the city). This fact has brought the city and its wealthiest residents into direct interface and interference with the sea, a condition that serves, on the one hand, to situate those residents closer to the symbolic heartland of the Australian settler-colonial regime, and with it all the associated national mythology of the beach, but which, on the other hand, has also made these residents’ properties the frontline of catastrophic climate change. As the Earth system continues to destabilise, greater energy storage in the atmosphere, combined with rising sea levels, increasingly makes this interface unviable. At the same time, the countermeasures taken by those who choose to fight these effects, to preserve their access to the beach and guarantee the supremacy of private property, are proving to be increasingly unreasonable and destructive, representative of the wider ideological and imaginative deficit of the settler-colonial project of Australia.</p>
<p>This tendency plays out in Tweed Heads, in the northernmost region of New South Wales. There, in the twentieth century, breakwaters were built to interrupt the ocean-borne transport of sand across the mouth of the Tweed River. This was done to keep the river navigable, but had the effect of accelerating the erosion of the beaches of the South Gold Coast, further north along the coast, as these beaches became choked of what had hitherto been a regular supply of fresh sand. That access to the beach would disappear, as a function of the disappearance of the beach itself, was deemed unacceptable by government, not least because of the importance of these beaches to the local tourist economy. The solution was a permanently operating sand pump, the so-called Tweed Sand Bypass, which since 2005 has sucked up sand from the Tweed harbour entrance, pumped it in pipes a kilometre north and across state boundaries, and dumped it on the South Gold Coast beaches. It is through this machine — an intervention that does not address a so-called natural process but the effects of an anthropogenic intervention elsewhere — we can understand that the beach of the Australian mythos, in addition to being a site of conflict, is also an entirely manufactured site. It must constantly be upkept, a fact that jars with the image of the beach as a natural Australian asset, a free gift of nature that takes care of itself.</p>
<p>Another beach that captures these conditions in vivid detail is Collaroy-Narrabeen Beach, the longest and most central in a cycloidal chain of beaches that runs from the mouth of Sydney Harbour to the mouth of Broken Bay, the northern limit of Sydney. The beach is lined with a series of suburbs built on the stolen land of the Gari people. These suburbs constitute the federal district of Mackellar, named in part after Dorethea Mackellar, the poet whose work <em>My Country</em> is a staple of Australian geo-myth, and whose electors voted right-wing in every election until the election of a so-called ‘teal’ independent candidate (conservative but concerned with the climate) in 2020.</p>
    `,
    sidenotes: [
      {
        image: "/assets/aside-2.jpg",
      },
      {
        html: `E. Phillips Fox, Landing of Captain Cook at Botany Bay, 1770 [detail], 1902, oil on canvas, 192.2 x 265.4 cm`,
      },
      {
        image: "/assets/aside-1.jpg",
      },
      {
        html: `Max Dupain, Sunbaker [detail], 1937, gelatin silver photograph, 37.9 x 42.8 cm.`
      },
    ],
  },
  {
    type: "media",
    file: "/assets/ConcreteBlocks.jpg",
    caption: `Tetrahedral concrete blocks such as these were cast during the Second World War to be deployed along Australian coastlines and frustrate amphibious assaults. They were moved to Collaroy-Narrabeen beach only after the war for defence against a different enemy: coastal erosion. It is auspicious that the storm of 2016 landed on D-Day, the commemoration of another amphibious assault, and for which the storm is now known in certain literature.
    <br/><br/>Image credit: Oskar Johanson.`,
  },
  {
    type: "text",
    arrangement: "right",
    html: `
    <p>The changing political landscape of these suburbs can be in part understood in the context of the events of 2016. Between the 5th and 7th of June, an ‘East Coast Low’, a type of intense storm well known on the east coast of Australia, tracked southwards across the city, generating a strong, southward sweep along the beach and producing currents up to 2 metres a second<sup>7</sup>. The storm’s effects were amplified by its spanning the peaks of two high tides. In one short section, the storm removed approximately 14,000 cubic metres of sand, dumping it beyond the surf zone. Where there were ad hoc seawalls and coastal defences, these collapsed, undermining the line of houses that had been built up to the very hilt of the beach. Balconies, patios, gardens, and entire pools were ripped from their foundations and dragged across the beach. Sewerage mains, buried under the sand, were uncovered and broken apart, leaving raw sewerage to spill out onto the beach. In the aftermath, residents were evacuated by the police, for fear that their properties would collapse entirely or a gas line explode.</p>
   <p>Nobody died, but one can imagine how these residents would have been rocked by the experience of having to be escorted out of their home by police (and when properties that would have been valued in the many millions of dollars saw that value literally crumble into the sea). As various temporary measures for protecting the houses from further decay were put in place, work began on drawings for a coastal defence system to be built on behalf of the owners of 10 properties along one of the worst-affected stretches of the beach. The council had already stipulated that no coastal defences that diminished the beach would be accepted, so this solution was to be entirely built within private land.</p>
    `,
    sidenotes: [
      {
        value: 7,
        html: `Gordon, Angus, Britton, Greg, and Dickenson, Todd, <em>Collaroy Beach</em>, 2016. D Day Storm – Lessons learnt in proceedings of the NSW Coastal Conference, Coffs Harbour, Australia, 9-11 November 2016.`
      }
      // {
      //   value: 8,
      //   html: `Gordon, Angus, Britton, Greg, and Dickenson, Todd, Collaroy Beach, 2016. D Day Storm – Lessons learnt in proceedings of the NSW Coastal Conference, Coffs Harbour, Australia, 9-11 November 2016.`,
      // },
      // {
      //   value: 9,
      //   html: `Gordon, et al.`,
      // },
    ],
  },
  {
    type: "media",
    file: "/assets/Landing4.jpg",
  },
  {
    type: "media",
    file: "/assets/Landing5.jpg",
    caption: "Plans and sections of the sea wall, taken from the Northern Beaches Council planning portal."
  },
  {
    type: "text",
    html: `
<p>By 2018, plans for the wall were lodged, with submissions from the residents in support of the project. A common refrain in the submissions was that residents were ‘entitled’ to protect their land and that, in fact, by constructing this wall residents were performing a service in defending the busy public road that ran behind their properties<sup>8</sup>. Although we can question this entitlement, the residents were in a sense sanctioned by council, who, in eventually agreeing to the plans, as well agreeing to finance the construction by 10% (with another 10% coming from federal government), legitimated the claim that such a major private work for narrow, private gain wall would not damage the beach, its amenity, or public access<sup>9</sup>. In defending the decision to allow the wall to be built, a spokesperson for council pointed out that ‘Danish’ engineers, as well as local experts, had approved the plans<sup>10</sup>. But this defence was late in coming. The process by which the council received and then approved the plans for the wall was, at no point, publicly exhibited, and available only through the council’s planning website, a labyrinthine and technocratic place. Accordingly, the first many constituents knew about the plans was when construction finally began in 2021<sup>11</sup>.</p>
    `,
    sidenotes: [
      {
        value: 8,
        html: `See Development Application DA2018/1289, Northern Beaches Council planning portal.`,
      },
      {
        value: 9,
        html: `Northern Beaches Council website news page, <em>Understanding the Collaroy seawall challenge</em>, 26 November 2021. Available online: <a target="_blank" rel="noopeoner noreferrer" href="https://www.northernbeaches.nsw.gov.au/council/news/understanding-collaroy-seawall-challenge">https://www.northernbeaches.nsw.gov.au/council/news/understanding-collaroy-seawall-challenge</a>`,
      },
      {
        value: 10,
        html: `Kurmelovs, Royce, ‘A 7m wall has gone up on a Sydney beach: are we destroying public space to save private property?’, <em>The Guardian Australia</em>, 24 October 2021. Available online: <a target="_blank" href="https://www.theguardian.com/environment/2021/oct/24/a-7m-wall-has-gone-up-on-a-sydney-beach-are-we-destroying-public-space-to-save-private-property">https://www.theguardian.com/environment/2021/oct/24/a-7m-wall-has-gone-up-on-a-sydney-beach-are-we-destroying-public-space-to-save-private-property</a>`,
      },
      {
        value: 11,
        html: `In the writing of this paper I am indebted to Professor Short, whose insight into sand transport and the dynamics of beaches, as well as the goings-on of the Collaroy Beach episode, was invaluable.`,
      },
    ],
  },
  // {
  //   type: "media",
  //   file: "/assets/Landing2.jpg",
  // },
  {
    type: "text",
    arrangement: "right",
    html: `
<p>When the first stretch of the works was completed this year, the reaction from the community was outrage. Capped by a blue-green glass balustrade, a concrete wall descends vertically from the ground floor level of the houses, at the extreme seaward edge of the properties, by four metres. Here, it is substantially reinforced and supported by a concrete footing 1.2 metres deep and 0.6 metres wide, and which itself, on the seaward side, is reinforced by a substantial line of boulders. This footing sits on a continuous line of concrete piles that extend a further 5 metres into the sand. Attached at regular intervals along the footing and extending away from the sea downwards diagonally are permanent screw pile anchors 7.5 metres long. Cast into setback sections along the wall are private access steps for the residents.</p>
<p>Although designed to be buried by sand above the boulders, during the winter storms of 2022 the beach’s top layers of sand were dragged away, exposing the footing and the boulders along with broken pipe, builder’s materials, and chunks of the boulder. After these events, one could observe that, at times, no beach remained between the wall and the surf. It had largely disappeared<sup>12</sup>.</p> 
    `,
    sidenotes: [
      {
        value: 12,
        html: `During storm erosion, sand is moved from the beach to further back behind the surf zone, where it can accumulate in sand banks. In calmer weather, this sand slowly returns to the beach.`,
      },
    ],
  },
  {
    type: "text",
    arrangement: "left",
    html: `
    <p>How are we to account for the catastrophe of this wall, and its implications for the architecture of the Australian beach? The residents wanted protection, not only to secure themselves against the trauma of 2016 but presumably also to protect the value of the assets they inhabit. They felt entitled to secure this protection for themselves and felt entitled to compensation from the state to do so. On both of these senses of entitlement, the state was partially obliging, but in the interest of the public — and on whose behalf it held the commons of the beach — the state also stipulated that such protections were to be built within the property boundaries of those residents. Clearly wishing to preserve as much of the value of their properties as possible, the residents then demanded a solution from their engineers that would occupy the smallest possible footprint: a vertical wall. But a sea wall that is vertical is the most damaging form of architecture for the beach between it and the sea. Whereas a wall that gently slopes up and away from the sea, like a dune, allows waves to roll up and dissipate their energy, a vertical wall will reflect waves with almost all of their energy, allowing them to bounce back to the sea and drag the sand of the beach with them.</p>
    `,
    sidenotes: [
      {
        image: "/assets/aside-3.jpg",
      },
      {
        html: `Charles Meere, Australian beach pattern [detail], 1940, oil on canvas, 91.5 x 122.0 cm.`
      }
    ],
  },
  {
    type: "three",
    htmlTop: `<p>A vertical sea wall thus accelerates the process of sand erosion. This architectural fact is the key to the failure of the council’s decision-making. Private works they sanctioned will now continue to damage and narrow the beach, a public good, despite being limited within the bounds of private property. Threading together the wall, the pump, and thousands of other coastal defence schemes across the country is the overriding conviction that the Australian coast, to the extent it remains within the domain of the urban, is a territory to be dominated and produced, even to the point of self-annihilation, at which point it must be re-produced.</p>`,
    htmlBottom: `
    <p> It is the privileging of one sense of time — the time of real estate, of the financial cycle and value appreciation — over the deep and slow time by which sand is transported along the length of the continent, and from the floor of the continental shelf to where there was once were dunes, and back again. The entanglement of this architectural chauvinism with its identitarian counterpart, and the reservoir of colonial imagination from which it springs, must be understood if we are to engage in serious and just adaptation to catastrophic climate change. The beach, after all, is a model heterotopia; although it has not yet delivered it, the beach contains within it the promise of a counter society, and with it, counter architectural practices that more than likely are about the removal of buildings from duneland than their fortification of so-called ‘proprietal rights’.</p>
        `,
  },
  // {
  //   type: "text",
   
  // },
];