import React, { useEffect, useMemo, useRef } from "react"
import classNames from "classnames";

import { useScrollPercentage } from 'react-scroll-percentage'


import { structure } from "../data";
import Three from "./Three"

const Header = () => {
  return (
    <header className="relative ">
      <div className="header-parent">
        <h1 className="mb-0">Echo Works</h1>
        <div className="sidenote">
          <div className="mb-0"><p
          className="mb-2"
          style={{ textIndent: '0rem' }}
          >The manufacture of the Australian beach</p></div>
          <div>
            Research undertaken and presented by oskar johanson for it’s about time – the 10th Architecture Biennale Rotterdam.
          </div>
        </div>
      </div>
    </header>
  );
}

const Media = ({ file, caption }) => {
  return (
    <figure className="media item w-full section">
      <picture>
        <img src={file} alt={caption} />
      </picture>
      {caption && <figcaption className="px-[10px] py-[10px] max-w-[50rem]" dangerouslySetInnerHTML={{__html: caption }}  />}
    </figure>
  )
}

const Sidenote = ({
  value, html, image
}) => {
  return (
    <div className="mb-8 item sidenote">
      {image && <img src={image} />}
      {html && <>
        {value && <sup className="mr-1">{value}</sup>}
        <span dangerouslySetInnerHTML={{__html: html}} />
      </>}
    </div>
  )
}

export const TextBlock = ({ html, sidenotes, arrangement, isLast, className }) => {
  const requiresArrangement = useMemo(() => arrangement === "right", [arrangement])
  return (
    <section className={classNames(requiresArrangement && "right-position",
    isLast && "is-last", 
    className,
    "lg:my-24 text-group section")}>
        <div
          className={classNames(
            "text-block",
          )}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <aside
        >
          {sidenotes?.map((item, i) => (
            <Sidenote key={i} {...item} />
          ))}
        </aside>
    </section>
  );
}

const structureMap = (item, i) => {
  switch (item.type) {
    case "text":
      return <TextBlock isLast={i === structure.length - 1} key={i} {...item} />;
    case "media":
      return <Media {...item} key={i} />;
    case "three":
      return <Three {...item} key={i} />;
  }
}


const Landing = () => {
  const [ref, percentage] = useScrollPercentage({
    /* Optional options */
    threshold: 0,
  })

  return (
    <div ref={ref} className="h-[90vh] w-screen relative" style={{
      opacity: percentage < 0.56 ? 1 : percentage >= 0.56 && percentage < 0.8 ? 1 - ((percentage - 0.56) / (0.8 - 0.56)) : 0
    }}> 
    {percentage.toPrecision(2)}%
      <div className="absolute top-0 left-0 w-full h-full bg-[#EBEBEB] flex items-center justify-center"/>
      
      <img className="fixed h-full w-full object-cover top-0 left-0" src={`/assets/MainCover.jpg`} />

    </div>
  )
}

const App = () => {
  return (
    <>
      <Landing />
      {/* <Media file={"/assets/MainCover.jpg"} caption={"Collaroy Beach in 2022. Photo: Oskar Johanson."} /> */}

      <main className="relative z-[10] bg-white">
        <div className="">
          <Header />

          {structure.map(structureMap)}
        </div>

      </main>
    </>
  )
}

export default App