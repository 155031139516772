import React, { useMemo, useRef, useEffect, useState, Suspense } from "react";
import * as THREE from "three"
import { Canvas, useFrame, useThree } from "@react-three/fiber";

import { useScrollPercentage } from 'react-scroll-percentage'


import { Detailed, PerformanceMonitor } from "@react-three/drei"
 
import classNames from "classnames";

import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import { useScrollTracking } from "./useScroll";

import { TextBlock } from "./App"


function calculateOpacity(percentage, fadeInStart, fadeInEnd, fadeOutStart, fadeOutEnd) {
  if (percentage < fadeInStart || percentage > fadeOutEnd) {
    return 0;
  } else if (percentage >= fadeInStart && percentage < fadeInEnd) {
    return (percentage - fadeInStart) / (fadeInEnd - fadeInStart);
  } else if (percentage >= fadeInEnd && percentage < fadeOutStart) {
    return 1;
  } else if (percentage >= fadeOutStart && percentage <= fadeOutEnd) {
    return (fadeOutEnd - percentage) / (fadeOutEnd - fadeOutStart);
  }
}


function Scene({ percentage }) {
  const { set, invalidate } = useThree()
  const [mixer, setMixer] = useState(null)
  const [action, setAction] = useState(null)


  useEffect(() => {
    if (mixer) {
      mixer.setTime(percentage * 22);
      invalidate();
    }

  }, [percentage, mixer])


  useEffect(() => {
    function handleScroll() {
      invalidate();
    }

    window.addEventListener("scroll", handleScroll);

    return window.removeEventListener("scroll", handleScroll);
  }, []);

  
  const gltf = useLoader(GLTFLoader, "/assets/three/xxx.glb", loader => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco-gltf/');
    loader.setDRACOLoader(dracoLoader);

    loader.onLoad = () => {
      console.log("loaded")
    }
  });
  useEffect(() => {
    if (gltf.cameras) {
      console.log(gltf, "three")
      gltf.cameras[0].fov = 80,
      set({
        camera: gltf.cameras[0]
      })
    // setDefaultCameras(gltf.cameras[0]);
    }
    gltf.scene.children.forEach((child) => {
      if (child.isLight) {
        console.log(child, "CTV")
        child.intensity = 1
        // gltf.scene.remove(child);
      }
    });
  }, [gltf.cameras, gltf.scene]);

  useEffect(() => {
    if (gltf.animations) {
      const mixer = new THREE.AnimationMixer(gltf.scene);
      setMixer(mixer)

      gltf.animations.forEach(function (clip) {
        mixer.clipAction(clip).play();
      });
    }
  }, [gltf.animations])
  
  return (
    <Suspense fallback={null}>
      <Detailed distances={[0, 10, 20]}>
        <primitive object={gltf.scene} />
      </Detailed>
    </Suspense>
  );
}

const Three = ({ htmlTop, htmlBottom }) => {
  const [ref, percentage] = useScrollTracking({
    threshold: 0
  })

  const [dpr, setDpr] = useState(1);


  return (
    <div id="three" ref={ref} className="section">
      
      <div
        className="sticky top-0"
        style={{
          height: "100vh",
          width: "100vw",
          opacity: calculateOpacity(percentage, 0.14, 0.25, 0.75, 0.85)
        }}
      >
        <Canvas 
        linear={true}
        // shadows="basic"

        gl={{
          antialias: true,
          precision: "lowp",
          powerPreference: "high-performance",
          outputColorSpace: THREE.SRGBColorSpace,
          toneMapping: THREE.ACESFilmicToneMapping
        }}

        dpr={dpr} frameloop="demand">
          <ambientLight intensity={2} />
          <Scene percentage={percentage} />
        </Canvas>
      </div>

      <div className="relative z-[100]" style={{
        marginTop: "-100vh"
      }}>
        <TextBlock className={"three-d-text top"} html={htmlTop} />
        <div className="h-[5000px] w-full" />
        <TextBlock className={"three-d-text bottom"} html={htmlBottom} />
        <div className="pb-[100px] w-full" />
      </div>
    </div>
  );
};

export default Three